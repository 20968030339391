const CommercialBankingDivisionData = [
  {
    id: 8,
    stateName: "Washington",
    eventKey: "wa",
    title: "WA",
    contactData: [
      {
        name: "Bellingham Commercial Banking",
        contact: [
          {
            phone: "",
            addressLine1: "1500 Cornwall Ave",
            addressLine2: "",
            addressLine3: "Bellingham, WA 98225"
          }
        ]
      },
      {
        name: "Everett Commercial Banking",
        contact: [
          {
            phone: "",
            addressLine1: "9929 Evergreen Way",
            addressLine2: "",
            addressLine3: "Everett, WA 98204"
          }
        ]
      },
      {
        name: "Seattle Commercial Division",
        contact: [
          {
            phone: "",
            addressLine1: "425 Pike St",
            addressLine2: "",
            addressLine3: "Seattle, WA 98101"
          }
        ]
      },
      {
        name: "Seattle Commercial Real Estate Division",
        contact: [
          {
            phone: "",
            addressLine1: "425 Pike St",
            addressLine2: "",
            addressLine3: "Seattle, WA 98101"
          }
        ]
      }
    ]
  },
  {
    id: 6,
    stateName: "Oregon",
    eventKey: "or",
    title: "OR",
    contactData: [
      {
        name: "Portland Metro Commercial Division",
        contact: [
          {
            phone: "",
            addressLine1: "530 SW Alder Ave",
            addressLine2: "",
            addressLine3: "Portland, OR 97204"
          }
        ]
      },
      {
        name: "Portland Metro Commercial Real Estate Division",
        contact: [
          {
            phone: "",
            addressLine1: "530 SW Alder Ave",
            addressLine2: "",
            addressLine3: "Portland, OR 97204"
          }
        ]
      },
      {
        name: "Central Oregon Commercial Division",
        contact: [
          {
            phone: "541-330-1807",
            addressLine1: "572 SW Bluff Drive",
            addressLine2: "Suite 110",
            addressLine3: "Bend, OR 97702"
          },
          {
            phone: "541-880-5206",
            addressLine1: "803 Main Street",
            addressLine2: "Suite 300",
            addressLine3: "Klamath Falls, OR 97601"
          }
        ]
      },
      {
        name: "Southern Oregon Commercial Division",
        contact: [
          {
            phone: "",
            addressLine1: "101 E Broadway",
            addressLine2: "Suite 100",
            addressLine3: "Eugene, OR 97401"
          },
          {
            phone: "",
            addressLine1: "3220 Hillcrest Park Drive",
            addressLine2: "",
            addressLine3: "Medford, OR 97504"
          }
        ]
      },
      {
        name: "Agribusiness Division",
        contact: [
          {
            phone: "",
            addressLine1: "125 North E Street",
            addressLine2: "",
            addressLine3: "Lakeview, OR 97630"
          },
          {
            phone: "",
            addressLine1: "3220 Hillcrest Park Drive",
            addressLine2: "",
            addressLine3: "Medford, OR 97504"
          }
        ]
      }
    ]
  },
  {
    id: 2,
    stateName: "Idaho",
    eventKey: "id",
    title: "ID",
    contactData: [
      {
        name: "Western Idaho Commercial Division",
        contact: [
          {
            phone: "208-338-7380",
            addressLine1: "1001 W Idaho St",
            addressLine2: "",
            addressLine3: "Boise, ID 83702"
          }
        ]
      }
    ]
  },
  {
    id: 4,
    stateName: "Nevada",
    eventKey: "nv",
    title: "NV",
    contactData: [
      {
        name: "Nevada Commercial Banking Division",
        contact: [
          {
            phone: "702-243-4402",
            addressLine1: "10100 W Charleston Blvd",
            addressLine2: "Suite 110",
            addressLine3: "Las Vegas, NV 89135"
          }
        ]
      }
    ]
  },
  {
    id: 5,
    stateName: "Utah",
    eventKey: "ut",
    title: "UT",
    contactData: [
      {
        name: "Utah Commercial Real Estate Division",
        contact: [
          {
            phone: "801-366-2265",
            addressLine1: "405 S Main Street",
            addressLine2: "Suite 100",
            addressLine3: "Salt Lake City, UT 84111"
          }
        ]
      }
    ]
  },
  {
    id: 1,
    stateName: "Arizona",
    eventKey: "az",
    title: "AZ",
    contactData: [
      {
        name: "Northeast Arizona Commercial Division",
        contact: [
          {
            phone: "480-607-4821",
            addressLine1: "6720 N Scottsdale Road",
            addressLine2: "Suite 111",
            addressLine3: "Scottsdale, AZ 85253"
          },
          {
            phone: "520-232-5325",
            addressLine1: "8001 N Oracle Road",
            addressLine2: "Suite 102",
            addressLine3: "Tucson, AZ 85704"
          }
        ]
      },
      {
        name: "Northeast Arizona Commercial Real Estate Division",
        contact: [
          {
            phone: "480-607-4821",
            addressLine1: "6720 N Scottsdale Road",
            addressLine2: "Suite 111",
            addressLine3: "Scottsdale, AZ 85253"
          }
        ]
      }
    ]
  },
  {
    id: 3,
    stateName: "New Mexico",
    eventKey: "nm",
    title: "NM",
    contactData: [
      {
        name: "New Mexico Central Commercial Banking Division",
        contact: [
          {
            phone: "505-341-7300",
            addressLine1: "4400 Osuna Rd NE",
            addressLine2: "",
            addressLine3: "Albuquerque, NM 87109"
          },
          {
            phone: "505-946-6555",
            addressLine1: "208 Washington",
            addressLine2: "",
            addressLine3: "Santa Fe, NM 87501"
          },
          {
            phone: "575-522-2664",
            addressLine1: "1800 S Telshor Blvd",
            addressLine2: "",
            addressLine3: "Las Cruces, NM 88011"
          },
          {
            phone: "505-726-6500",
            addressLine1: "221 W Aztec Ave",
            addressLine2: "",
            addressLine3: "Gallup, NM 87301"
          }
        ]
      }
    ]
  },
  {
    id: 7,
    stateName: "Texas",
    eventKey: "tx",
    title: "TX",
    contactData: [
      {
        name: "Dallas Metro Commercial Division",
        contact: [
          {
            phone: "214-526-5843",
            addressLine1: "5420 LBJ Freeway",
            addressLine2: "Suite 200",
            addressLine3: "Dallas, TX 75240"
          }
        ]
      },
      {
        name: "Dallas Metro Commercial Real Estate Division",
        contact: [
          {
            phone: "214-526-5843",
            addressLine1: "5420 LBJ Freeway",
            addressLine2: "Suite 200",
            addressLine3: "Dallas, TX 75240"
          }
        ]
      },
      {
        name: "Austin Metro Commercial Real Estate Division",
        contact: [
          {
            phone: "",
            addressLine1: "3800 N Lamar Blvd",
            addressLine2: "Suite 135",
            addressLine3: "Austin, TX 78756"
          }
        ]
      },
      {
        name: "Energy Division",
        contact: [
          {
            phone: "972-419-4169",
            addressLine1: "5420 LBJ Freeway",
            addressLine2: "Suite 200",
            addressLine3: "Dallas, TX 75240"
          }
        ]
      }
    ]
  }
];

export default CommercialBankingDivisionData;
